import styled from "styled-components";
import MassHumanitiesLogo from "../../images/massHumanitiesLogo.png";

const About = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Header>
          <Rowdies>ABOUT</Rowdies>
          <Noto>關於我們</Noto>
        </Header>
        <span>
          <SubHeader>What is the Immigrant History Trail?</SubHeader>
          <Content>
            The Immigrant History Trail is a multimedia public art project that
            activates Boston Chinatown's community archives to share stories
            about the neighborhood's working class immigrant histories.
          </Content>
          <Content>
            Plaques will hang on the walls of buildings across Boston Chinatown,
            inviting visitors to navigate the neighborhood with curiosity. QR
            codes link to this website, which highlights relevant text, images,
            and audio fragments, and points to where visitors can learn more.
          </Content>
          <Content>
            This project is a community stewarded resource that will be updated
            regularly with new contributions of sites and stories for the
            Immigrant History Trail.
          </Content>
        </span>
        <ImageWrapper>
          <img
            src="https://res.cloudinary.com/dmi3ywlal/image/upload/v1717759301/neu_37778_h7yecj.jpg"
            alt="test"
          />
          {/* press cmd + / or ctrl + / to reactivate (un-comment) the caption line, the bottom one is for chinese text */}
          {/* <span>caption goes here</span> */}
          {/* <span style={{ fontFamily: "Noto Serif TC, sans serif" }}>caption goes here</span> */}
        </ImageWrapper>
        <span>
          <SubHeader>Why?</SubHeader>
          <Content>
            Boston Chinatown is at a crossroads after absorbing decades of urban
            renewal, zoning injustice, and institutional expansion, followed by
            luxury highrise development. The future of the immigrant, working
            -class residential and small business core that has characterized
            the neighborhood for 180 years is at risk. Now, more than ever,
            there is a need to make working -class immigrant histories of
            Chinatown known, accessible, and celebrated.
          </Content>
        </span>
        <span>
          <SubHeader>How?</SubHeader>
          <Content>
            Boston has a long history of neighborhood activism, and the
            Immigrant History Trail involves a similar grassroots and iterative
            mentality. We will engage local community members to collaborate
            with us as advisors and participants by contributing stories and
            memories, selecting sites for the Trail, creating archival
            resources, and more. We are committed to creating a participatory,
            non-linear Trail that emerges from community involvement. Residents,
            workers, small business owners, and organizers can all contribute
            stories, research, and audiovisual media, and engage their fellow
            community members.
          </Content>
        </span>
        <span>
          <SubHeader>Who?</SubHeader>
          <Content>
            We would like to thank Mass Humanities and the City of Boston
            Transformative Public Art Grant for support. We would also like to
            thank our partners including Boston Research Center and all current
            and past volunteer Immigrant History Trail Committee members.
          </Content>
          <Content>
            While the Chinatown Community Land Trust is launching the Immigrant
            History Trail, the project idea originated during a conversation
            among many community partners held at the Chinese Historical Society
            of New England (CHSNE) in 2019, as community members discussed
            historic preservation for the 2020 Chinatown Master Plan. We would
            like to honor Tunney Lee (1931-2020), who was present in this
            conversation and enthusiastic about creating an iterative QR-code
            trail to show Chinatown's archival images to its visitors.
          </Content>
          <Content>
            The core Immigrant History Trail team includes Daphne Xu, Lydia
            Lowe, Vivian WuWong, Lily Xie, Elisha Zhao, Franny Xi Wu. The
            website team includes Eric Han, Wei Qi, Diane Zhou. Fabrication and
            installation of physical signage is by Jaywalk Studio. The Immigrant
            History Trail has a volunteer Advisory Committee which works to
            continue building the Trail.
          </Content>
        </span>
        <ImageWrapper>
          <img
            src="https://res.cloudinary.com/dmi3ywlal/image/upload/v1717769294/Jianhua_Tang_garden_p53zqs.jpg"
            alt="test"
          />
          {/* <span>caption goes here</span> */}
          {/* <span style={{ fontFamily: "Noto Serif TC, sans serif" }}>caption goes here</span> */}
        </ImageWrapper>
        <span>
          <SubHeader font="Noto Serif TC">移民歷史徑是什麼?</SubHeader>
          <Content font="Noto Serif TC">
            移民歷史徑是一個多媒體公共藝術項目,透過喚醒波士頓華埠的社區檔案,分享這個社區內工薪階級移民的歷史故事。
          </Content>
          <Content font="Noto Serif TC">
            我們將在華埠各建築物的牆面上掛起牌匾,邀請訪客帶著好奇心探索這個街區。掃描牆上的二維碼就可以跳轉到網站,閱覽相關的文章、圖片和音頻片段,並指引訪客了解更多資訊。我們將在啟動儀式上進行四個牌匾的步行導覽。
          </Content>
          <Content font="Noto Serif TC">
            這個項目是一個由社區管理的資源,將會定期更新、加入新的地點和故事,豐富移民歷史徑。
          </Content>
        </span>
        <span>
          <SubHeader font="Noto Serif TC">為什麼有移民歷史徑?</SubHeader>
          <Content font="Noto Serif TC">
            在數十年的現代化、不公平地區劃分、大型機構擴
            展後，又迎來了豪華高端化發展，波士頓華端口正
            走到屬於它的分岔路口。在過去108年建造起華埠
            靈魂的勞工階級住所和小生意核心地帶，還有移民
            的未來，全都面臨危機。與過去任何時候相比，當下
            便是最需要讓大眾學習、得到和慶祝勞工階級移民 歷史的重要時刻。
          </Content>
        </span>
        <span>
          <SubHeader font="Noto Serif TC">如何建造移民歷史徑?</SubHeader>
          <Content font="Noto Serif TC">
            波士頓擁有悠久的社區運動歷史，而移民歷史徑則
            擁有相似且迭代的草根精神。我們會和社區本地成
            員合作，擔任顧問或參與者的角色，分享他們的故
            事、為小徑選址、創造檔案資源等等。我們承諾這將
            是一個富有參與性、非直線、由社區內誕生的小徑。
            居民、工人、小生意持有者和組織者通通都可以奉
            獻自己的故事、研究、影音媒體和邀請他們的同伴 來參與。
          </Content>
        </span>
        <ImageWrapper>
          <img
            src="https://res.cloudinary.com/dmi3ywlal/image/upload/v1717843345/talking-at_rally_lepbig.jpg"
            alt="test"
          />
          {/* <span>caption goes here</span> */}
          {/* <span style={{ fontFamily: "Noto Serif TC, sans serif" }}>caption goes here</span> */}
        </ImageWrapper>
        <span>
          <SubHeader font="Noto Serif TC">誰創造了移民歷史徑?</SubHeader>
          <Content font="Noto Serif TC">
            我們衷心感謝麻省人道協會和波士頓市政府改革性
            公共藝術的補助支持。我們也衷心感謝我們的搭檔
            ，包括波士頓研究中心和參與過或正在參與移民歷
            史小徑工作小組的志工們。
          </Content>
          <Content font="Noto Serif TC">
            雖然華端口土地信託會負責執行移民歷史徑，整個
            計畫想法其實來自於2019年新英格蘭地帶華人歷史
            社會內的一次社區搭檔對話，當時社區成員們正在
            討論2020華埠十年計劃內的保留歷史部分。我們將 會銘記Tunney
            Lee(1931-2020)在這次討論中表現
            出來對創造為訪客展示華埠存檔照片迭代二維碼的 巨大熱情。
          </Content>
          <Content font="Noto Serif TC">
            移民歷史徑的主要成員包括Daphne Xu, Lydia Lowe, Vivian WuWong, Lily
            Xie, Elisha Zhao, Franny Xi Wu。創建網站小組包括Eric Han, Wei Qi,
            Diane Zhou。實體招牌的組裝與設立來
            自Jaywalk工作室。移民歷史小徑有一個繼續建立小 徑的顧問志工小組。
          </Content>
        </span>
        <Border />
        <span>
          <SubHeader style={{ fontWeight: "bold" }}>Acknowledgements</SubHeader>
          <Content>
            The Immigrant History Trail is funded in part by the Mayor's Office
            of Arts and Culture of the City of Boston, Mass Humanities with
            funding from the Barr Foundation, and by a grant from the National
            Trust Preservation Fund of the National Trust for Historic
            Preservation.
          </Content>
          <Content>
            Thank you to the members of the Immigrant History Trail Committee
            which advised the project, conducted research, wrote and edited
            text, found photographs, and performed many other tasks to begin
            building the trail: Christina Chan, Krista Chan, Collyn Chan, Chutze
            Chou, Stephanie Fan, Maria Fong, Olivia Fong, Dory Klein, Henry Ko,
            Michael Liu, Nancy Lo, Lydia Lowe, Feronia Mei, Joseph Nevins,
            Franny Xi Wu, Jean Wu, Vivian WuWong, Lily Xie, Daphne Xu, Hongyan
            Yang, Cynthia Yee, Kenneth Yee, Anita Yip, Elisha Zhao,
          </Content>
          <Content>
            Thank you to Alice Kane and the Chinese Historical Society of New
            England; Kenneth Eng, Nancy Lo, and the Chinese Consolidated
            Benevolent Association; Dory Klein and the Boston Public Library;
            Giordana Mecagni and the Northeastern University Archives; Chloe
            Bordewich and Lydia Harrington of the Little Syria Project; and the
            Pao Arts Center for their partnership, advice, support, and sharing
            of resources as we developed the Immigrant History Trail.
          </Content>
          <Content>
            Thank you to the interviewees who have contributed time to build and
            share their oral histories: Joanne Bethoney Carter, Eva Peter
            Chojnowski, Betty Lutfy Dimeco, Russell Eng, Ann Moy, Brian Moy,
            Marie Moy, George Salah, Rosanne Solomon, Jianhua Tang, Jeffrey
            Wong, Cynthia Yee
          </Content>
          <Content>
            Thank you to Professor Hongyan Yang and her Boston College course
            Making American Landscapes: Building and Living in Asian America for
            extensive support in researching the following sites. 25-29 Beach
            Street: Ines Angara Hamilton A Areddy Jennifer L Bartlett Cassandra
            J Kavanagh Chinese Merchants Association: Maxwell K. Guan Ashton Kao
            Dan T. Nguyen Ethan Sun Globe Theater: Ric J. DeLyons Kaetlynn C.
            King-Velez Mary E. Zagrocki Hong Far Low Restaurant: Shirley J.
            Canales Kiran Hebert Claire Kildahl Aina Yamagishi John Assaf Home:
            Connor J. Capshaw Emma J. Cunningham Nicholas R. Rhodes Katelynn
            Wasta Kwong Kow Chinese School: Alisa G. Cufone Grace L. Concannon
            Hi'ilei G. Leong Kendall Torres Maryknoll Sisters Center: Isabella
            M. Ayalew Josiah S. Odhiambo Mica Quelle Saifya N. Stewart
            Shakespeare Inn: Jeremiah Alhassan Tommy Bi Ashley S. Choi Jenna
            Jiang Sofia Rugova Tunney Lee Home: Chelsea Lee Madeline G. Cunniff
            Mae S. Carroll Sophia C. Comparato
          </Content>
          <Content>
            Thank you to Giselle Chu, Emily Hiltunen, and Rachel Liu, project
            assistants for the class. Thank you to their following advisors:
            Eddie Bonilla, Lynn Johnson, Michael Liu, Lydia Lowe, Steve Runge,
            Erin Scheopner, Vivian WuWong.
          </Content>
        </span>
        <Sponsers>
          <img src={MassHumanitiesLogo} alt="mass humanities logo" />
        </Sponsers>
      </ContentWrapper>
    </Wrapper>
  );
};

const Noto = styled.div`
  font-family: "Noto Serif TC", sans-serif;
  color: inherit;
`;

const ImageWrapper = styled.div`
  font-family: "Quattrocento";
  color: #423f67;

  img {
    width: 100%;
    height: auto;
  }
  span {
    margin-top: 8px;
  }
`;

const Rowdies = styled.div`
  font-family: "Rowdies", sans-serif;
`;

const Sponsers = styled.div`
  font-family: "Rowdies";
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  color: #423f67;

  img {
    height: 100px;
  }
`;

const Border = styled.div`
  margin: 40px 0px;
  height: 1px;
  width: 100%;

  background: rgba(66, 63, 103, 0.25);
`;

const Content = styled.div`
  font-family: ${(props) =>
    props.font === "Noto Serif TC" ? "Noto Serif TC" : "Quattrocento"};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;

  color: #1e1e1e;
`;

const SubHeader = styled.div`
  margin-top: 8px;

  font-family: ${(props) =>
    props.font === "Noto Serif TC" ? "Noto Serif TC" : "Rowdies"};
  font-style: normal;
  font-weight: ${(props) => (props.font === "Noto Serif TC" ? 900 : 300)};
  font-size: ${(props) => (props.font === "Noto Serif TC" ? "24px" : "18px")};
  line-height: 30px;

  color: #423f67;
`;

const Header = styled.div`
  font-style: normal;
  font-size: 32px;
  line-height: 120%;
  font-weight: ${(props) => (props.font === "Noto Serif TC" ? 900 : 300)};

  gap: 6px;
  display: flex;
  flex-direction: column;

  text-transform: uppercase;

  color: #423f67;

  div:not(:first-child) {
    font-weight: 800;
  }
`;

const Wrapper = styled.div`
  background-color: #fbf6e9;
  box-sizing: border-box;
  padding: 33px;
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  margin-top: 33px;
  padding: 32px;
  width: 900px;
  display: flex;
  gap: 48px;

  flex-direction: column;
  box-sizing: border-box;

  @media (max-width: 600px) {
    margin-top: 0px;
    padding: 0px;
  }

  span {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export default About;
