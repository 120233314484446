const data = 
    [
        {
            name: "Parcel C C地塊",
            address: "38 Ash St",
            chineseAddress: "雅樹街38號",
            location: {"lat": 42.3484769, "lng": -71.0635552},
            hint: "We want a community center, not a stinky parking garage. 我們要的是社區中心，不是臭氣熏天的停車場。",
            cover: 
                "https://res.cloudinary.com/dmi3ywlal/image/upload/v1717759301/neu_37778_h7yecj.jpg",
            landmarkId: 'a',
            id: "parcel-c",
            sign: true,
            audio: [
                {
                    name: 'Marie Moy, long-time resident, on her opposition to the Parcel C parking garage proposal. Interviewed on January 15, 2023.',
                    src: 'https://res.cloudinary.com/dmi3ywlal/video/upload/v1731636874/mariemoy_parcelc_vf5qxl.mp3'
                }, 
            ],
            images : [
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717759301/neu_37778_h7yecj.jpg',
                    caption:"Suzanne Lee holds the bullhorn for fourth grader Mark Liu to talk about why he wants a community center on Parcel C.",
                    chCaption:"李素影舉著擴音器讓四年級學生馬克·劉講述為何他希望在C地塊建設一個社區中心。"
                },
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717759496/neu_37768_rvhq75.jpg',
                    caption:"After reading in the news that the New England Medical Center signed a three-way agreement to withdraw its parking garage proposal, coalition members organized an impromptu victory march through the streets of Chinatown.",
                    chCaption:"在得知新英格蘭醫療中心簽署三方協議撤回其停車庫提案後，聯盟成員在華埠街道組織了一次即興勝利遊行。"
                },
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717767424/neu_37253_xbq17r.jpg',
                    caption:"Children from the Quincy School at one of many protests against the parking garage proposed for Parcel C.",
                    chCaption:"昆西學校的孩子們參加反對C地塊停車庫提案的眾多抗議活動之一。"
                },
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717767408/neu_36498_qw6qw3.jpg',
                    caption:"Though not recognized during the official ceremony, community coalition members picked up the shovels and staged their own groundbreaking photo to celebrate winning the land for community development.", 
                    chCaption:"雖然在正式儀式上沒有被認可，社區聯盟成員拿起鏟子拍攝了自己的奠基照片，以慶祝贏得土地進行社區開發。"
                },
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717759301/neu_37518_trmmfe.jpg',
                    caption:"The community holds a Recreation Day on Parcel C during the 18-month organizing campaign.",
                    chCaption:"在18個月的組織活動期間，社區成員在C地塊舉辦了一個娛樂日。"
                },
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717767543/neu_37633_kibpg0.jpg',
                    caption:"Resident activists staged a ribbon-cutting ceremony to dramatize the call for a community center during Recreation Day on Parcel C.",
                    chCaption:"在C地塊舉辦的遊樂日，社區組織者設計舉行了一場剪綵儀式，以戲劇性地呼籲建設社區中心。"
                },
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717759300/BRA_broken_promises_sitin_2004_h0dbfc.jpg',
                    caption:"Chinese Progressive Association members stage an impromptu sit-in at the Boston Redevelopment Authority office, supported by City Councilors Chuck Turner and Felix Arroyo, Sr., 2004.",
                    chCaption:"2004年，華人前進會成員在波士頓重建局辦公室舉行臨時靜坐，得到市議員查克·特納和費利克斯·阿羅約的支持。"
                },
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717759301/neu_36573_sgco1b.jpg',
                    caption:"“Let the community vote!” posters advertise a community-sponsored referendum on the proposed Parcel C parking garage.",
                    chCaption:"“讓社區投票！”海報宣傳社區發起的關於C地塊停車庫提案的公投。"
                },
            ],
            description: [
                {
                    type: 'en',
                    default: 
                        "The land upon which the Metropolitan was built, known as Parcel C, is an important piece of Chinatown's story."
                },
                {
                    type: 'en',
                    default: 
                        "Parcel C was home to immigrant families until the 1950s, when most of these row houses were razed as part of the federal Urban Renewal Program. In the 1970s, activist volunteers began to utilize a vacant row house at 34-36 Oak Street to provide community services. "
                },
                {
                    type: 'en',
                    default: 
                        "To highlight the community's claim to the land, the Quincy School Community Council (now the Boston Chinatown Neighborhood Center) brought community members together to paint the Chinatown History Mural designed by artists David Fichter and Wen-ti Tsen on the 34-36 Oak Street row house.  A replica of the mural can be seen inside the Boston Chinatown Neighborhood Center lobby at 38 Ash Street. The City of Boston deeded the building to the Center for $1 in 1987, after defeating a legal challenge by Tufts-New England Medical Center."
                },
                {
                    type: 'en',
                    default: 
                        "For over a decade, the community opposed three different hospital proposals to build a parking garage on Parcel C.  In 1993, the Coalition to Protect Parcel C for Chinatown organized an independent referendum in which community members rejected the latest garage proposal by a large margin of 1692:42.  Despite the overwhelming sentiment against the garage, it took eighteen months of protests and petitions for the Boston Redevelopment Authority to designate the parcel for community-oriented development."
                },
                {
                    type: 'en',
                    default: 
                        "Hundreds of residents who opposed the parking garage proposal then participated in community meetings to plan for development of Parcel C, demanding and winning bilingual participatory processes for planning and development review.  In 2004, the Metropolitan opened with a combination of market-rate and affordable housing, rental and condominium, and space for community organizations.  "
                },
                {
                    type: 'en',
                    default: 
                        "The resident awareness and involvement sparked by the Parcel C struggle has continued to endure and shape the Chinatown community."
                },
                {
                    type: 'ch',
                    default: "信義大廈腳下的這片土地叫做 C 地段，是華埠歷史上重要的里程碑。"
                },
                {
                    type: 'ch',
                    default: "C 地段一直是移民家庭的家園，直到 1950 年代，這裡的大部分排屋在聯邦城市更新計劃中被夷為平地。1970 年代起，社區組織志願者開始在屋街 34-36 號的一棟空置排屋中提供社區服務。"
                },
                {
                    type: 'ch',
                    default: "為了突顯社區對這片土地的管控權，昆士學校社區委員會（現為波士頓華埠社區中心）組織社區居民在屋街 34-36 號的排屋牆上繪製了由藝術家大衛·費克特和曾文棣設計的華埠歷史壁畫。這幅壁畫的複製品保存在雅樹街 38 號波士頓華埠社區中心的大堂內。2018 年，波士頓市在擊敗塔夫茨-新英格蘭醫療中心的法律挑戰後，以 1 美元的價格將這棟排屋轉讓給了華埠社區中心。"
                },
                {
                    type: 'ch',
                    default: "十多年來，社區抵制了三個在 C 地段上建造醫院停車庫的提案。1993 年，保護華埠 C 地段聯盟組織了一次獨立公投，社區成員以 1692 票反對：42 票支持的壓倒性多數拒絕了最後一個大型停車庫的提案。儘管面臨社區的一致反對，波士頓重建局在持續了 18 個月的抗議和請願後，才將該地塊指定為社區開發用地。"
                },
                {
                    type: 'ch',
                    default: "數百名參與了反對停車庫提案的居民隨後參加了社區會議，共同規劃 C 地段的開發，要求並實現了規劃和開發過程中的雙語參與。信義大廈與 2004 年開業，結合了市場價和可負擔住房、出租屋和公寓、以及社區機構的辦公室。"
                },
                {
                    type: 'ch',
                    default: "圍繞著 C 地段的鬥爭引發了居民的抗爭意識，公民的持續參與塑造著華埠的社區。"
                },
            ],
            quote: [
                {
                    type: 'en',
                    default: 
                        "It was the community residents who won Parcel C back. The community worked very hard to win Parcel C back; it is crucial that the Boston Redevelopment Authority and the developers continue to communicate with the residents on any development plans for Parcel C. No one can just make decisions behind closed doors among themselves without the input of the residents.  Whoever plans for its development must listen to the residents' voices."
                },
                {
                    type: 'ch',
                    default: 
                        "是社區居民贏回了 C 地段。我們社區為了贏回C地段付出了巨大的努力；波士頓重建局和開發商必須持續與居民就 C 地段的任何開發計劃保持溝通。決策過程中不能排除居民的意見，背地裡自行做決定。任何計劃進行規劃發展的人，都必須聽取居民的聲音。"
                },
                {
                    type: 'attribution',
                    default: 
                        'Henry Yee, Co-chair of the Boston Chinatown Resident Association 余仕昂，華埠居民會聯合主席'
                },
            ],
            resources: [
                {
                    title: "The Struggle over Parcel C: How Boston's Chinatown Won a Victory in the Fight Against Institutional Expansionism and Environmental Racism",
                    author: "by Andrew Leong, University of Massachusetts Boston",
                    date: "September 1, 1997",
                    chTitle: "",
                    chAuthor: "",
                    link: "https://drive.google.com/file/d/1nxs5dxMwjjl5X048HAGsnk-uUaQQojeJ/view",
                },
                {
                    title: "Parcel C Organizing Timeline",
                    date: "",
                    author: "",
                    chTitle: "",
                    chDate: "",
                    chAuthor: "",
                    link: "https://docs.google.com/document/d/1d4ZoXWJTPjBoZQNNPm6fO6pAEzlItc4r/edit?usp=sharing&ouid=117396012309039941372&rtpof=true&sd=true",
                },
                {
                    title: "Reconstructed statement about Parcel C from Henry Yee / 余仕昂有关 C 地段重建的聲明",
                    author: "by Henry Yee 作者：余仕昂",
                    date: "April 6, 1998 1998年4月6日",
                    link: "https://drive.google.com/file/d/1kKk3c-0hwkKjN-30STlVelR3B8TWqLMJ/view",
                },
                {
                    title: "Parcel C Coalition Letter",
                    date: "July 14, 1993 & August 3, 1993",
                    author: "List of members of the Coalition to Protect Parcel C for Chinatown",
                    chTitle: "",
                    chDate: "",
                    chAuthor: "",
                    link: "https://drive.google.com/file/d/1Ph18VUIHH0rYnTX1aSzp1ICkzTM95-qJ/view?usp=sharing",
                },
                {
                    title: "Parcel C Meeting Notes",
                    date: "April 21, 1998",
                    chTitle: "",
                    chDate: "",
                    link: "https://drive.google.com/file/d/1cnXpAbs5hk5RdXyqIDplxmNDYxDWmJ9w/view?usp=sharing",
                },
                {
                    title: "Interview with Marie Moy on Parcel C",
                    date: "October 9, 2008",
                    author: "by Lydia Lowe",
                    chTitle: "",
                    chDate: "",
                    link: "https://drive.google.com/file/d/13SZM-BUNr6u2na18J1OPIjD8NWi5NWW9/view",
                },
                {
                    title: "Northeastern University Archives and Special Collection",
                    date: "",
                    author: "",
                    chTitle: "",
                    chDate: "",
                    link: "https://archivesspace.library.northeastern.edu/repositories/2/archival_objects/303901",
                },
            ]
        },
        {
            name: "Row Houses 排屋",
            address: "29 Oak Street",
            chineseAddress: "屋街29號",
            location: {"lat": 42.3483134, "lng": -71.0632459},
            cover: 
                "https://res.cloudinary.com/pang-dev/image/upload/v1677709248/CCLT/Jianhua_Tang_garden_k2npqh.jpg",
            hint: "One day, I saw an elderly woman squatting outside the barbed wire fence of our yard, happily counting my melons. 有一天，我看到一位老婦人蹲在我們院子外興高采烈地數著我的瓜。",
            landmarkId :"b",
            id: "row-houses",
            sign: true,
            audio: [
                {
                    name: 'Jianhua Tang, former Johnny Court resident, on growing melons in her backyard garden (Cantonese) 前約翰尼庭院居民湯建華口述她在後院花園種植冬瓜的經歷（粵語）',
                    src: 'https://res.cloudinary.com/pang-dev/video/upload/v1677709255/CCLT/donggua.mp3'
                }, 
                {
                    name: 'Jianhua Tang, Johnny Court in the 1990s (Cantonese) 湯建華口述1990年代的華埠(粵語)',
                    src: 'https://res.cloudinary.com/pang-dev/video/upload/v1677709267/CCLT/tjh1-2.mp3'
                },
                {
                    name: 'Marie Moy, Oak Street, 1950s and 1970s - 2017',
                    src: 'https://res.cloudinary.com/dmi3ywlal/video/upload/v1717820156/mariemoy_rowhouses_lol1xq.mp3'
                }, 
                {
                    name: 'Eva Peter Chojnowski, Tyler Street, 1930s to 1950s',
                    src: 'https://res.cloudinary.com/dmi3ywlal/video/upload/v1717820156/evachojnowski_rowhouses_m1oq5h.mp3'
                }, 
            ],
            images: [
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717769294/Jianhua_Tang_garden_p53zqs.jpg',
                    caption:"Former resident Jianhua Tang in her backyard garden on Johnny Court. (CPA photo by Kye Liang)",
                    chCaption:"前居民湯建華在約翰尼庭院後院她的菜園中。（攝影：Kye Liang，華人前進會）"
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717769927/eva_family_before_her_birth_apzk15.jpg',
                    caption:"Family photo of Louis and Alice Peter.  Louis was known as Zathar Peter, for the product that he sold in the community, then known as Little Syria or Syrian Town. (Photo courtesy of Eva Peter Chojnowski)",
                    chCaption:"路易斯和愛麗絲·彼得的家庭照片。路易斯因在社區內銷售產品而被稱為Zathar Peter，當時該社區被稱為小敘利亞或敘利亞鎮。(照片由Eva Peter Chojnowski提供) "
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717769828/eva_s_parents_hudson_st_behind_v17gem.jpg',
                    caption:"Eva Peter Chojnowski’s parents, with a view of Hudson Street behind them.",
                    chCaption:"Eva Peter Chojnowski的父母，背後可見乞臣街。(照片由Eva Peter Chojnowski提供) "
                },
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717769995/eva_at_jqs_dennison_behind_uqu4jw.jpg',
                    caption:"Eva Peter Chojnowski as a young girl in the schoolyard. (Photo courtesy of Eva Peter Chojnowski; for more photographs and interviews, see Little Syria)",
                    chCaption:"年輕時的Eva Peter Chojnowski在學校的院子裡。(照片由Eva Peter Chojnowski提供；更多照片和訪談請參見小敘利亞計劃) "
                },
            ],
            description: [
                {
                    type: 'en', 
                    default: "Boston Chinatown has two centuries of history as an anchor neighborhood for successive waves of immigrant working-class families. The neighborhood was home to Irish, European, Syrian/Lebanese, Jewish, and African American families, as well as the Chinese.  Much of Chinatown was known as Little Syria in the early 20th century, becoming more heavily Chinese after the repeal of the Chinese Exclusion Act in 1943."
                },
                {
                    type: 'en',
                    default: "The Oak Street and Johnny Court row houses preserve the small-scale character of the original South Cove neighborhood. These homes were developed by landowner John Wells between 1832-1837, when the tidal flats were reclaimed and completed during an economic depression known as the Panic of 1837."
                },
                {
                    type: 'en',
                    default: "Chinatown was wracked by Urban Renewal in the 1950s and 60s, hospital and university expansion through the 1980s, followed by a wave of luxury high-rise developments.  Many row house homes then fell prey to the short-term rental market before local regulations began in 2018. "
                },
                {
                    type: 'en',
                    default: "This building, 29 Oak Street, is Boston's first condominium that is permanently preserved as affordable housing through a 99-year community land trust ground lease established by the Chinatown Community Land Trust."
                },
                {
                    type: 'ch',
                    default: "作為移民工人階級家庭的核心支柱，波士頓的華埠已有兩個世紀的歷史。這個街區曾是愛爾蘭、歐洲、敘利亞/黎巴嫩、猶太、非裔，以及華裔家庭的家園。在20世紀初，華埠的很大一部分街區被稱為“小敘利亞”，但在1943年排華法案被廢除後，這裡的華裔居民逐漸增加。"
                },
                {
                    type: 'ch',
                    default: "屋街和約翰尼庭院的排屋保留了原南灣街區的小規模特徵。這些房屋由地主約翰·威爾斯在1832至1837年間開發，當時潮間帶被開墾並在被稱為“1837年恐慌”的經濟蕭條期間完工。"
                },
                {
                    type: 'ch',
                    default: "在1950和60年代，華埠經歷了城市更新，隨後在1980年代又經歷了醫院和大學的擴建，接著遭遇了一波豪華高樓開發的浪潮。許多排屋因此淪為短期租賃市場的犧牲品，直到2018年，社區成員共同呼籲保護歷史排屋和居住其中的移民居民，對短期租賃開發實施了更強的監管措施。"
                },
                {
                    type: 'ch',
                    default: "這棟位於屋街29號的排屋是波士頓首個永久保留為可負擔住房的公寓，通過華埠社區土地信託會設立的99年社區土地信託地租條約來實現。"
                },
            ],
            quote: [
                {
                    type: 'en',
                    default: 'When we lived in Johnny Court, Oak Terrace and Quincy Tower were nearby, as well as some elderly housing buildings. In the evenings, some residents would pass by the row houses on their after-dinner walk. I saw an elderly woman squatting outside the barbed wire fence of our yard, happily counting my winter melons. A real sense of accomplishment emerged at that time, "Ha, I was a teacher for 28 years, and I learned how to grow melons after coming to the states." Our neighbors to the left and right, in all the row houses on the entire street were all Chinese. We had no language barriers. After meals, we greeted each other and chit-chatted on the stoop. Life was quite fun. Transportation was also convenient, it was easy for me to study English and seek help from civic organizations. I also had a piece of land to grow produce, it was my own happy little world.'
                },
                {
                    type: 'en',
                    default: 'I harvested over 20 winter melons each year, weighing 200+ pounds in total. Some melons were 8 or 10 pounds, one of them weighed over twenty pounds! I stored them in our basement to be consumed slowly throughout the year. When it snowed in the winter and I couldn’t go out to buy produce, I cut up winter melons to make soup - lean meat soup, pork bone soup, bean soup, chicken soup... It`s also delicious when braised with dried shrimp and fermented bean curd. Regardless if you stew or stir-fry, winter melons can be enjoyed in many ways. I shared my harvest with friends and relatives at times, who were all ecstatic to receive them. They would slowly and stingily consume the melons, reluctant to gobble them up immediately.'
                },{
                    type: 'ch',
                    default: "我們住在約翰尼庭院時，附近有華信屋和君子樓，還有些老人屋。有些居民晚上吃飽飯、散步經過排屋。我看到一個老婆婆蹲在我鐵絲網的圍欄外面，蹲在那裡好開心地數我的瓜。我當時就真的很有一種成就感，“哈，我拿了28年的粉筆，來到美國居然學會了種瓜。”而且我們的鄰居呢，左右隔壁、整個排屋、整條街道兩排的住客全部都是中國人。那我們語言溝通就沒問題，茶餘飯後大家出門聊聊天、打招呼，生活上都蠻有樂趣的。因為交通也方便，我學英文、求助社團組織也方便，自己也有一塊地種一下，好像一個歡樂的小天地。"
                },
                {
                    type: 'ch',
                    default: "我每年收穫的冬瓜就有二十幾個，總共大概兩百多磅。有些一個瓜十磅八磅，有一個重的二十幾磅。我把瓜放在土庫裡，可以一年慢慢吃。尤其是冬天下雪的時候，我不能出去買菜，就斬開冬瓜來煲湯：煲瘦肉湯也可以，豬骨湯、豆湯、雞湯也行。拿蝦米腐乳來煮冬瓜也很美味的。不管煲湯還是炒菜，冬瓜有很多種吃法。有些時候我分給朋友們、親戚們，他們都很開心的。一個瓜慢慢切開來吃，他們都捨不得吃完。"
                },
                {
                    type: 'attribution',
                    default: 'Tang Jianhua 湯建華'
                },
            ],
            resources: [
                {
                    title: "Interview with Tang Jianhua 湯建華採訪",
                    author: "by Daphne Xu 徐乙漾",
                    date: "",
                    chTitle: "",
                    chAuthor: "",
                    link: "https://drive.google.com/file/d/1GD6aOmc_XZl31nHf9As8Gu5Cukf7jm2B/view",
                },
                {
                    title: "Interview with Marie Moy on Oak Street",
                    author: "by Lydia Lowe",
                    date: "January 15, 2023",
                    chTitle: "",
                    chAuthor: "",
                    link: "https://drive.google.com/file/d/1WHSguOTdo2o95iQASG99Zt5NsPpBHnD7/view",
                },
                {
                    title: "Interview with Eva Peter Chojnowski",
                    author: "by Lydia Lowe",
                    date: "October 13, 2023",
                    chTitle: "",
                    chAuthor: "",
                    link: "https://drive.google.com/file/d/1ktL-Vlq0dd7qPbDpSytzabqfMfqjRhHq/view",
                },
            ]
        },
        {
            name: "Reggie Wong Memorial Park 黃述沾紀念公園",
            address: "129 Kneeland Street",
            chineseAddress: "尼倫街129號",
            location: {"lat": 42.3498339, "lng": -71.0586184},
            heading: 200,
            cover: 
                'https://res.cloudinary.com/pang-dev/image/upload/v1677709241/CCLT/WBUR1-1920x2442_i2lmlj.jpg',
            hint: "I was raised on the streets of Chinatown! 我是在華埠的街頭長大的！",
            landmarkId :"c",
            id: "reggie-wong-park",
            sign: true,
            images : [
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717833820/WBUR1-1920x2442_zpbtew.jpg',
                    caption:"",
                    chCaption:""
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717833301/22_09_000085_image_access_full_crs448.jpg',
                    caption:"",
                    chCaption:""
                }, 
            ],
            audio: [
                {
                    name: '“Streets of Chinatown” was Reggie Wong’s favorite song, written and sung by Kevin So, http://kevinso.com/.',
                    src: 'https://res.cloudinary.com/dmi3ywlal/video/upload/v1717833166/StreetsOfChinatown_Acoustic_nir49q.mp3'
                }
            ],
            description: [
                {
                    type: 'en', 
                    default: "Reggie Wong Park is named after the much loved entrepreneur, community leader, and Chinese 9-man volleyball tournament organizer Reggie Wong."
                },
                {
                    type: 'en',
                    default: "Originally called Pagoda Park, this gritty urban court space at the corner of Lincoln and Kneeland Streets, between highway on and off ramps, has for decades been Chinatown’s only public, outdoor recreational park. The park was born as Chinatown kids went looking for places to play sports, eventually turning an empty field into the park you see today. Now the park is entering a new era of community governance and care by local neighbors, volleyball teams, and skateboarders committed to protecting this shared resource."
                },
                {
                    type: 'en',
                    default: "Reggie Wong Park is the New England home of Chinese 9-Man Volleyball, a sport brought over by immigrants from Taishan, China and rooted in Chinatown’s history as a bachelor society due to the impact of the Chinese Exclusion Act. Separated from their families and isolated by exclusion and racial violence, laundrymen and restaurant workers found community together with 9-man volleyball as their only healthy social outlet. The rules of 9-man were consciously designed to preserve Chinese cultural traditions."
                },
                {
                    type: 'en',
                    default: 
                    "Teams from Boston and Providence organized the first inter-city 9-man tournament in 1938. Since then, it has grown into a popular tournament that travels between North American cities and brings together thousands of players and spectators every Labor Day weekend."
                },
                {
                    type: 'ch', 
                    default: "黃述沾紀念公園以深受愛戴的企業家、社區領袖及中國九人排球賽事組織者黃述沾先生的名字命名。"
                },
                {
                    type: 'ch',
                    default: 
                        "公園原名寶塔公園，位於林肯街與尼伦街交叉處，夾在高速公路的進出匝道之間，多年來一直是唐人街唯一的公共戶外休閒場所。公園的起源是唐人街的孩子們尋找運動場地，最終將一塊空地變成了今天所見的城市球场。現在，公園正進入一個新的時期，由社區的居民、排球隊員和滑板運動者共同致力於維護這一共享資源。"
                },
                {
                    type: 'ch',
                    default: 
                        "黃述沾公園是九人排球在新英格蘭的核心場地。這項運動由中國台山的移民引入美國，並因排華法案的影響在唐人街的單身社會中扎根。當時的洗衣工和餐館工作人員在與家人分離和種族排斥的背景下發現了社區和九人排球，這成為他們唯一的健康社交方式。九人排球的規則是為了保留華人文化傳統而特別设计的。"
                },
                {
                    type: 'ch',
                    default: 
                        "自1938年波士頓與普羅維登斯的球隊首次舉辦城際九人排球錦標賽以來，該活動已成為北美城市間流行的年度盛事，每年勞動節周末都吸引數千名球員和觀眾參加。"
                },
            ],
            quote: [
                {
                    type: 'en',
                    default: 'So the men would just go to work every day and on their one day off they’d get together and they’d play volleyball–they didn’t even have a volleyball, they rolled up a towel and wrapped it up and they used that as a volleyball.'
                },
                {
                    type: 'ch',
                    default: '男人們每天都要上班，在他們休息的那一天就會聚在一起打排球。他們以前連排球都沒有，捲起一條毛巾包起來就當是排球了。'
                },
                {
                    type: 'attribution',
                    default: "Russell Eng, President of Friends of Reggie Wong Memorial Park and Reggie Wong's nephew 伍國龍，黃述沾侄子、黃述沾纪念公园之友主席"
                },
            ],
            resources: [
                {
                    title: "Interview with Russell Eng",
                    author: "by Daphne Xu",
                    date: "2021",
                    chTitle: "",
                    chAuthor: "",
                    link: "https://docs.google.com/document/d/1bKRkmyUqyZ5-8yYktaaffcgVzxYk0EWH2nQ92vlmkWc/edit?usp=sharing",
                },
                {
                    title: "Reggie Wong Park brings local sports teams together during August Moon Tournament",
                    author: "by Lydia Lowe",
                    date: "August 21, 2020",
                    chTitle: "",
                    chAuthor: "",
                    link: "https://sampan.org/2020/top-news/reggie-wong-park-brings-local-sports-teams-together-during-august-moon-tournament/",
                },
                {
                    title: "What is Chinese 9 Man Volleyball?",
                    author: "by Elevate Yourself on Youtube",
                    date: "",
                    chTitle: "",
                    chAuthor: "",
                    link: "https://youtu.be/mB6hKD9DQho",
                },
                {
                    title: "Chinatown’s 9-man tournament takes its Labor Day showdown to Providence",
                    author: "by Aimee Moon",
                    date: "September 2, 2022",
                    chTitle: "",
                    chAuthor: "",
                    link: "https://www.wbur.org/news/2022/09/02/9man-volleyball-providence",
                },
                {
                    title: "North American Chinese Invitational Volleyball Tournament",
                    author: "",
                    date: "",
                    chTitle: "",
                    chAuthor: "",
                    link: "https://en.wikipedia.org/wiki/NACIVT",
                },
            ]
        },
        {
            name: "Shanghai Printing 上海印刷公司",
            address: "16 Oxford Street",
            chineseAddress: "好事福街16號",
            location: {"lat": 42.3519267, "lng": -71.0605273},
            heading: 75.2,
            cover: 
                'https://res.cloudinary.com/pang-dev/image/upload/v1699932868/CCLT/print3.jpg',
            hint: "The only Chinese printer in Boston. 波士頓唯一的華語印刷商。",
            landmarkId :"d",
            id: "shanghai-printing",
            sign: false,
            images : [
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717834929/ShanghaiPrinting_1_uvqy3x.jpg',
                    caption:"Henry Wong relaxing at Shanghai Printing. (Photo courtesy of Wong family)",
                    chCaption:"王耀敏在上海印刷公司小憩。(照片由王氏家族提供) "
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717834928/ShanghaiPrinting_2_i1kfoj.png',
                    caption:"Laundry ticket printed at Shanghai Printing. (Photo courtesy of Chinese Historical Society of New England)",
                    chCaption:"上海印刷公司印製的洗衣票。(照片由紐英倫華人歷史協會提供)"
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717834929/ShanghaiPrinting_3_hnmal6.jpg',
                    caption:"Inside Shanghai Printing with press running. (Photo courtesy of Wong family)",
                    chCaption:"上海印刷公司內，印刷機正在運轉。(照片由王氏家族提供) "
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717834930/ShanghaiPrinting_4_ewob8k.jpg',
                    caption:"Shanghai Printing was featured in New England Printer and Lithographer in 1955. ",
                    chCaption:"上海印刷公司在1955年被刊登於「新英格蘭印刷商與平版印刷家」。"
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717834929/ShanghaiPrinting_5_yoru93.jpg',
                    caption:"Daily Boston Globe clipping from May 29, 1949 featuring Shanghai Printing. (Photo courtesy of Wong family)",
                    chCaption:"1949年5月29日「波士頓環球報」的剪報，介紹上海印刷公司。(照片由王氏家族提供) "
                }, 
            ],
            audio: [
                {
                    name: 'Jeffrey Wong, owner of Shanghai Printing, on his business and its relationship with the Chinatown community. Interviewed on January 27, 2023.',
                    src: 'https://res.cloudinary.com/dmi3ywlal/video/upload/v1717835496/ShanghaiPrinting_JeffreyWong_ho4qp7.mp3'
                }
            ],
            description: [
                {
                    type: 'en', 
                    default: "Henry You Min Wong traveled by sea, train, and then sea again across the Pacific Ocean and the North American continent to settle in Boston in 1928. Still just a teenager, Wong worked at a laundry, then produced noodles for a different family business, working long hours with little community around him. At age 18, he bought a printing press from his cousin and named it the Shanghai Printing Company."
                },
                {
                    type:'en',
                    default: "Wong’s enterprise would become the leading press for the New England Chinese community. With the growth of the Chinese population, there was a demand for Chinese-language materials, from civic documents and social announcements to Chinese restaurant menus and laundry tickets. The Shanghai Printing Company wasn’t just an entrepreneurial success story. It helped to connect the social fabric of the new migrant population as it struggled to find its footing in a new land."
                },
                {
                    type:'en',
                    default: "It can be said that immigrants live two lives at once. One in their new home, and another in their native tongue. Prior to the repeal of the Chinese Exclusion Act in 1943, the Chinese population of Boston was sparse and decentralized. News from the homeland was part of what held this new community together, particularly during the wartime years when recent migrants sought updates about China’s struggle against Japanese occupation."
                },                
                {
                    type:'en',
                    default: "Shanghai Printing would become one of the longest-running presses in the area. Wong brought news and information to the community and was personally involved in helping newcomers through the immigration process or mentoring new business owners.  A co-founder of the Chinese Progressive Association, he advocated for diplomatic relations with China and improved labor standards in Chinatown."
                },                
                {
                    type:'en',
                    default: "Wong’s son Jeffrey took over the business, and, for another 40 years, printed wedding announcements and invitations, business cards and legal documents, rental agreements and news, all the vital printed material to help the Chinatown community prosper."
                },
                {
                    type:'ch',
                    default: "王耀敏 (Henry You Min Wong) 於1928年移居波士頓。年輕的他經歷了搭船、乘火車，再次橫渡太平洋和北美大陸的旅程，最終定居波士頓。當時還是青少年的他在洗衣店工作，隨後又在另一個家族企業製作麵條，長時間工作，周圍幾乎沒有華人。18歲時，他從表兄弟那兒買下了一家印刷廠，並命名為上海印刷公司。"
                },
                {
                    type:'ch',
                    default: "王耀敏的企業逐漸成為新英格蘭華人社區的主要印刷公司。隨著華人人口的增長，中文材料的需求也日益增加，從政府文件和社會公告到中餐館菜單和洗衣票據，無所不包。上海印刷公司不僅是一個成功的企業故事，更是幫助新移民在陌生的土地上尋找立足點，連結社會結構的重要橋樑。"
                },
                {
                    type:'ch',
                    default: "可以說，移民同時過著兩種生活。一種是在他們的新家園，另一種是在他們的母語中。1943年《排華法案》廢除之前，波士頓的華人社區稀少且分散。來自故鄉的新聞是凝聚這個新興社區的關鍵，尤其是在戰爭年代，新移民們渴望了解中國抗日戰爭的最新動態。"
                },
                {
                    type:'ch',
                    default: "上海印刷公司成為該地區運營時間最長的印刷公司之一。王耀敏為社區帶來了新聞和資訊，並親自幫助新移民通過移民流程，或指導新業主。他是華人進步協會的聯合創始人，倡導與中國建立外交關係，改善華埠的勞工標準。"
                },
                {
                    type:'ch',
                    default: "王耀敏的兒子王英健 (Jeffrey Wong) 接管了這家企業，並在接下來的40年裡繼續經營，印製婚禮公告和邀請函、名片和法律文件、租賃協議和新聞等所有重要的印刷材料，幫助華埠社區繁榮發展。"
                },
            ],
            quote: [
                {
                    type: 'en',
                    default: 'After 1937, when the Sino-Japanese War began, I bought a 1032-vintage radio to follow the latest news, such as the Battle for Shanghai, the December 8th affair etc. I was translating the news into Chinese and then setting them into print. These fliers were then sold for one or two cents, and everybody was reading them in order to follow the latest development.'
                },
                {
                    type: 'ch',
                    default: '1937年中日甲午戰爭爆發后，我買了一台1032舊收音機，用來收聽最新消息，比如上海保衛戰、十二月八日事件等。我把新聞翻譯成中文，然後印刷出來。這些傳單接著以一或兩美分的價格出售，每個人都在閱讀它們以瞭解最新的戰事發展。'
                },
                {
                    type: 'attribution',
                    default: "Henry Wong, Excerpted from Chinese Progressive Association Oral History Project interview, 1982 王耀敏，摘自華人前進會口述歷史項目，1982年"
                },
            ],
            resources: [
                {
                    title: "Henry Wong, Part of My Picture",
                    author: "by Vivian Wu",
                    date: "December 14, 1990",
                    chTitle: "",
                    chAuthor: "",
                    link: "https://drive.google.com/file/d/1mCLAiX1CQf1xT5B6o1vwAL5leHwVJj_k/view?usp=sharing",
                },
                {
                    title: "Oral History Interview with Henry Wong 王耀敏先生口述歷史",
                    author: "",
                    date: "December 14, 1990",
                    chTitle: "",
                    chAuthor: "",
                    link: "https://drive.google.com/file/d/1mCLAiX1CQf1xT5B6o1vwAL5leHwVJj_k/view?usp=sharing",
                },
                {
                    title: "Interview with Jeffrey Wong",
                    author: "by Vivian WuWong",
                    date: "January 27, 2023",
                    chTitle: "",
                    chAuthor: "",
                    link: "https://drive.google.com/file/d/1bXKCQoCQxKAHf8zSyWJHomA6PnCsB7dO/view?usp=sharing",
                },
            ],
        },
        {
            name: "China Pearl 龍鳳",
            address: "9 Tyler Street",
            chineseAddress: "泰勒街9號",
            location: {"lat": 42.3510199, "lng": -71.060649},
            cover: 
                "https://res.cloudinary.com/dmi3ywlal/image/upload/v1717838730/ChinaPearl_2-images-0_fswfkq.jpg",
            hint: "The first day he landed in America, he found a job at China Pearl. 他抵達美國的第一天，就在華珍餐廳找到了工作。",
            landmarkId :"e",
            id: "china-pearl",
            sign: true,
            images: [
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717838730/ChinaPearl_2-images-0_fswfkq.jpg',
                    caption:"Friends at a wedding banquet in the 1980s.",
                    chCaption:"1980年代一場婚宴上的朋友們。"
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717838233/ChinaPearl_1_skufis.jpg',
                    caption:"Historic photograph of China Pearl exterior.",
                    chCaption:"華珍餐廳外部的歷史照片。"
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717838612/ChinaPearl_3_overyx.jpg',
                    caption:"2005 Chinese New Year at China Pearl with community organizations.",
                    chCaption:"2005年華珍餐廳與社區組織共慶農曆新年。"
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717838234/ChinaPearl_4_fkwn8b.jpg',
                    caption:"Customers dining at China Pearl.",
                    chCaption:"顧客在華珍餐廳用餐。"
                }, 
            ],
            description: [
                {
                    type: 'en', 
                    default: "Chinese American food and restaurants are embedded in our national culture. While many original Chinese settlements have disappeared across the country, no major American city lacks a Chinese restaurant. But that popularity was hard won. Chinese restaurants in Boston contended with police raids and enduring stereotypes well into the 20th century, often being labeled as raucous establishments. The clientele included Chinese, Jewish, and Syrian neighbors, as well as outsiders, with sometimes gaudy décor to highlight the restaurant as an exotic destination. The Daily Advertiser in 1900 described Chinatown’s restaurants as “a visiting place for the curious.”  By the 1940s, restaurateurs reported that three-quarters of the customer base was non-Chinese."
                },
                {
                    type: 'en',
                    default: "The rise of Chinatown as a cultural cornerstone of the city owes much to the diligence, ingenuity, and spirit of its residents, business owners, and workforce. Restaurants have served as significant employers for generations of immigrants in Chinatown and throughout the region."
                },
                {
                    type: 'en',
                    default: "One of Boston Chinatown’s oldest establishments is the China Pearl, opened in 1960 by Yoke Soon “Billy” Chin, whose family roots in the neighborhood date back to the 19th century.  Located at 9 Tyler Street, it occupies the same space as its predecessor, Hon Loy Doo, a Chinese restaurant operating since 1935. Chin and his partners would go on to open eighteen different Chinese restaurants and eateries in Boston during the 1970s and 80s."
                },
                {
                    type: 'en',
                    default: "At the beginning, it wasn’t smooth sailing. Chin once joked that the stillness and quiet in the restaurant made a passerby think it was a funeral home. “In the first three months, we almost failed,” he recalled. Relentless effort turned the China Pearl into not just a bustling restaurant but a destination. Alongside his brother Frank, Billy made the China Pearl a hub for political fundraising activities to increase the influence of Chinese Americans in Boston."
                },
                {
                    type: 'en',
                    default: "Ricky Moy, who had worked in the dining hall, took over the business in 1989. The Moy family continued China Pearl’s legacy as not just a restaurant but a space to connect the community to the world. The large event space hosted wedding parties as well as New Year banquets for non-profits, neighborhood associations, and politicians. Brian Moy, Ricky’s son, described it as a gathering place. “The community really banded together…around…China Pearl, doing the fundraisers, getting to meet the politicians and having their voice be heard.” Brian and his sister Patty continue to own and operate the China Pearl, now with a second location, making it an intergenerational venture and maintaining its legacy to the spirit of community and entrepreneurship in Chinatown. "
                },
                {
                    type: 'ch',
                    default: "中餐和餐館深植於美籍華人的民族文化。雖然美國境內許多原本的華人定居點已經消失，但沒有一個主要的美國城市是沒有中餐館的。這種受歡迎程度來之不易。波士頓的中餐館在20世紀一直飽受警察的突襲搜查和長期存在的刻板印象的困擾，經常被標籤為喧鬧低俗的場所。顧客包括華人、猶太人和敘利亞人鄰居，以及外來人士，有時用俗麗花哨的裝飾來突顯餐廳的異國情調。1900年的《每日廣告報》將華埠的餐廳描述為「好奇者的必去之地」。到20世紀40年代，餐館老闆們稱中餐館裏四分之三的顧客群體都不是華人。"
                },
                {
                    type: 'ch',
                    default: "華埠作為城市文化基石的崛起在很大程度上歸功於居民、小生意者和勞動力的勤奮、聰明才智和精神。 餐館產業一直是華埠和整個地區幾代移民的重要雇主。"
                },
                {
                    type: 'ch',
                    default: "華珍（現稱龍鳳）是波士頓華埠最古老的餐館之一，由陳毓璇於 1960 年開設，他的家族在社區的起源可以追溯到 19 世紀。餐館位於泰勒街9 號，與它前身的Hon Loy Doo 佔據相同的空間，Hon Loy Doo 是一家自1935 年開始營業的中餐館。 1970 年代和 80 年代，陳毓璇和他的合夥人繼續在波士頓開設了 18 家不同的中餐館和食肆。"
                },
                {
                    type: 'ch',
                    default: "開始時，一切並不順利。 陳毓璇曾經開玩笑說，餐館裡沉寂的氛圍讓路人以為這是一家殯儀館。 「前三個月，我們幾乎失敗了」，他回憶道。 經過不懈的努力，華珍成為了一家熱鬧的餐館，更是一個社區內的必去之處。 陳毓璇與他的幼弟陳毓禮一起，將華珍打造成為政治籌款活動的中心，以提高華裔美國人在波士頓的影響力。"
                },
                {
                    type: 'ch',
                    default: "曾在餐館工作的梅錫鋭於 1989 年接管了這家餐館。 梅氏家族延續了華珍的傳統，它不僅是一家餐館，而且是一個將社區與外界聯繫起來的空間。這個大型活動的空間舉辦了婚禮派對，為非牟利組織、社區協會和政界人士舉辦新年宴會。 梅錫鋭的兒子梅沛傑將其描述為一個聚會的場所。 “社區真的團結在一起……圍繞著……龍鳳，進行籌款活動，會見政客並讓他們的聲音被聽到。” 梅沛傑和他的妹妹梅佩凡繼續經營龍鳳，現在已經有了第二個地點，使其成為跨代企業，並保留了華埠的社區精神和創業精神。"
                },
            ],
            quote: [
                {
                    type: 'en',
                    default: "And I feel so happy, it's the, some customers, or the young people, they're getting married in China Pearl, and then they get the baby born, and they get a party on that. And then, you know, his mother, for her birthday, and for bai jau, and, you know, the party, you know. The relation, they keep it for a long time, you know."
                },
                {
                    type: 'ch',
                    default: '我感到非常高興，有些顧客，或者年輕人，他們在華珍結婚，然後他們有了孩子也在這裡慶祝。然後，你知道，他母親祝壽和辦酒席也會來華珍。這種關係保持了很長時間。'
                },
                {
                    type: 'attribution',
                    default: "Ricky Moy, Former Owner of China Pearl 梅錫鋭，華珍餐廳前任老闆"
                },
            ],
        },
        {
            name: "Syriantown 小敘利亞",
            address: "76 Tyler Street",
            chineseAddress: "泰勒街76號",
            location: {"lat": 42.3491962, "lng": -71.0613881},
            cover: 
                "https://res.cloudinary.com/dmi3ywlal/image/upload/v1717840119/LittleSyria_1_sab55a.png",
            hint: "In Syrian Town, everybody was Auntie. 在小敘利亞，每個人都是阿姨。",
            landmarkId :"f",
            id: "little-syria",
            sign: true,
            images: [
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717840119/LittleSyria_1_sab55a.png',
                    caption:"Syrian family sitting on a front step on Hudson Street, 1909. Photographer unknown. (Photo courtesy of Boston Herald, Traveler Photo Morgue, Boston Public Library)",
                    chCaption:"1909年，一個敘利亞家庭坐在哈德遜街的台階上。攝影師不詳。(照片由「波士頓先驅報」旅行者照片資料庫，波士頓公共圖書館提供)"
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717840264/LittleSyria_2_uzjzhb.jpg',
                    caption:"1942 Josiah Quincy School class photo. (Photo courtesy of Eva Peter Chojnowski)",
                    chCaption:"1942年約塞亞·昆士學校的班級照片。(照片由Eva Peter Chojnowski提供)"
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717840328/LittleSyria_3_m9xgxq.jpg',
                    caption:"Eva Peter Chojnowski as a young girl in front of her home at 94 Tyler Street. (Photo courtesy of Eva Peter Chojnowski)",
                    chCaption:"兒時的Eva Peter Chojnowski在她位於泰勒街94號的家門前。(照片由Eva Peter Chojnowski提供)"
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717840119/LittleSyria_4_mp0hp0.jpg',
                    caption:"Nile Restaurant, formerly at 52 Hudson Street, 1960. (Photo courtesy of Little Syria Project)",
                    chCaption:"尼羅河餐廳，原址位於乞臣街52號，1960年。(照片由小敘利亞計劃提供)"
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717840119/LittleSyria_4_mp0hp0.jpg',
                    caption:'"Syrians of Boston" at the Liberty Loan Drive fundraiser on Boston Common in 1918. (Photo courtesy of Little Syria Project)',
                    chCaption:"1918年「波士頓的敘利亞人」在波士頓公園的自由貸款籌款活動中。(照片由小敘利亞計劃提供)"
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717840121/LittleSyria_6_fdepbe.jpg',
                    caption:"St. George Syrian Orthodox Church, formerly at 154 Tyler Street, which stood there from 1923 to 1953. (Photo courtesy of Little Syria Project)",
                    chCaption:"聖喬治敘利亞東正教教堂，原址位於泰勒街154號，屹立於1923年到1953年。(照片由小敘利亞計劃提供)"
                }, 
            ],
            description: [
                {
                    type: 'en', 
                    default: "76 Tyler Street was one of several residences in the neighborhood associated with Lebanese American poet Kahlil Gibran, best known as the author of The Prophet. Following his migration to Boston in 1895,  Gibran first lived on Oliver Place. He studied at the Josiah Quincy School and took English and art classes across the street at the Denison House, a settlement house run by elite women’s colleges, with volunteers like Amelia Earhart, who was well-known in the local community. Here, Gibran met his publisher and benefactors and began to enter the literary and Bohemian art world. Although he eventually left Boston for New York, Gibran stayed and wrote here at 76 Tyler Street, in his sister Marianna’s apartment, on frequent extended visits to the city."
                },
                {
                    type: 'en',
                    default: "Arabic-speaking immigrants from present-day Syria and Lebanon began settling in the South Cove in the late 1880s. The lands they came from were part of the Ottoman Empire, and these new arrivals were then collectively known as Syrians. By 1920, Boston had the third largest population of Syrians after New York and Detroit; by the late 1930s, according to the Boston Globe, the population had reached 15,000."
                },
                {
                    type: 'en',
                    default: "The early community crowded into brick tenements around Oliver Place (later Ping On Alley) and Oxford Street. By the early twentieth century, social and commercial life in Little Syria, often called “Syriantown” or the “Syrian Colony,” centered on nearby Tyler and Hudson Streets. Nearly all residents were Christian—Maronite, Orthodox, and Melkite. In the 1910s, several Syrians elsewhere in the country successfully petitioned U.S. courts to be legally considered white, and the group was consequently guaranteed the right to naturalization. Muslim Arab immigrants were not granted the same right until 1944."
                },
                {
                    type: 'en',
                    default: "A common arrangement was to have a dry goods store on the first floor, supplies for pack peddlers in the basement, and living spaces rented out upstairs. Peddling was a common profession among both men and women, first on foot and later by car. But Syrians were also writers, teachers, priests, musicians, factory workers, shoemakers, lacemakers, restaurateurs, bookies, acrobats, and boarding house owners. One such boarding house, run by Khalil and Annie Nassar, sat at 73 ½ Beach Street (site of the Chinatown Gate). "
                },
                {
                    type: 'en',
                    default: "The Syrian and Chinese populations overlapped in the South Cove. Shopkeepers frequently did business with each other, Garment District workers labored in the same factories, and children of both backgrounds, as well as many others, attended the Josiah Quincy School on Tyler Street—now the Chinese Consolidated Benevolent Association community center. "
                },
                {
                    type: 'en',
                    default: "World War I was a period of intensive communal ferment as residents banded together to aid the villages from which they had come, and which were now enduring devastating blight and famine. Numerous civic organizations were formed, among them the Lebanese Syrian Ladies’ Aid Society (still active) and the Syrian American Club. The Arabic newspaper Fatat Boston was launched at 40 Tyler Street to deliver news of both the U.S. and the Middle East."
                },
                {
                    type: 'en',
                    default: "In the 1920s and 1930s, the Syrian community spread south down Shawmut Avenue. From the 1950s on, however, highway construction and urban renewal accelerated generational and socioeconomic shifts. Residents joined grassroots organizers in Chinatown and New York Streets to push back against demolition plans, but most Syrians eventually left. Today, former residents and their descendants are dispersed, with a significant concentration in West Roxbury, Norwood, and Dedham."
                },
                {
                    type: 'en',
                    default: "Contributed by Chloe Bordewich and Lydia Harrington, Little Syria Project"
                },
                {
                    type: 'ch',
                    default: "泰勒街 76 號是該地區與黎巴嫩裔美國詩人卡里爾·紀伯倫 (Kahlil Gibran) (1883-1931) 有關的幾處住宅之一，他以著作《先知》一書而聞名。 1895 年移居波士頓後，紀伯倫首先與他的家人住在奧利華巷（即現在的平安巷）。他在約西亞·昆士學校讀書，並在丹尼森之家 (Denison House) 上英語和藝術課，丹尼森之家是一所由精英女子學院經營的安置房，其義工包括在當地很出名的阿米莉亞·埃爾哈特(Amelia Earhart)。 正是在這裡，紀伯倫遇到了他的出版商、捐款者，並開始進入文學和波希米亞藝術界。儘管紀伯倫最終離開波士頓前往紐約，但他經常回波士頓長期探訪，並在泰勒街 76 號他妹妹瑪麗安娜的公寓裡留下來寫作。"
                },
                {
                    type: 'ch',
                    default: "來自現今的敘利亞和黎巴嫩的阿拉伯語移民於 1880 年代末期開始在南灣定居。 他們來自的土地是奧斯曼帝國的一部分，這些新來者當時被統稱為敘利亞人。 到 1920 年，波士頓的敘利亞人口數量位居第三，僅次於紐約和底特律； 根據《波士頓環球報》報道，到 1930 年代末，人口已達 15,000 人。"
                },
                {
                    type: 'ch',
                    default: "早期的社區擠滿了奧利佛廣場（後來的平安巷）和牛津街週圍的磚砌公寓。 到了二十世紀初，小敘利亞（通常被稱為「敘利亞鎮」或「敘利亞殖民地」）的社會和商業生活集中在附近的泰勒街和夏利臣道。 幾乎所有居民都是基督教徒——馬龍派、東正教和梅爾基派。 1910 年代，在國內某地方的幾名敘利亞人成功向美國法院請求在法律上被視為白人，該群體因此獲得了入籍權利。 直到 1944 年，穆斯林阿拉伯移民才被授予同樣的權利。"
                },
                {
                    type: 'ch',
                    default: "排屋常見的佈置是在​​一樓設有乾貨店，地下室為小販提供物資，樓上出租居住空間。 兜售是男性和女性的常見職業，首先是步行，後來是開車。 但敘利亞人也是作家、教師、牧師、音樂家、工廠工人、鞋匠、花邊製作工人、餐廳老闆、賭博經紀人、雜技演員和寄宿公寓老闆。 其中一間寄宿舎由哈利勒·納薩爾 (Khalil Nassar) 和安妮·納薩爾 (Annie Nassar) 經營，位於必珠街 73 ½ 號（華埠牌樓所在地）。"
                },
                {
                    type: 'ch',
                    default: "敘利亞和中國人口在南灣互疊。 店主們經常互相做生意，製衣區的工人在同一家工廠打工，兩種不同背景的孩子以及其他許多人都在泰勒街的約西亞·昆士學校（現為中華公所）讀書。"
                },
                {
                    type: 'ch',
                    default: "第一次世界大戰是一個公共騷動激烈的時期，居民們聯合起來援助他們所來自的村莊，而這些村莊現在正在遭受毀滅性的枯萎和飢荒。 許多民間組織成立，其中包括黎巴嫩敘利亞婦女援助協會（仍活躍）和敘利亞美國俱樂部。 阿拉伯語報紙《Fatat Boston》在泰勒街 40 號發行，主要報導美國和中東的新聞。"
                },
                {
                    type: 'ch',
                    default: "在 1920 年代和 1930 年代，敘利亞社區沿著所密大道向南蔓延。 然而，從 1950 年代開始，高速公路建設和城市更新加速了世代和社會經濟的轉變。 居民加入華埠和紐約街道的基層組織者，反對拆除計劃，但大多數敘利亞人最終是離開了。 如今，以前的居民及其後代已經分散，主要集中在西洛斯保里、羅活和戴德姆。"
                },
                {
                    type: 'ch',
                    default: "撰稿人：Chloe Bordewich 和 Lydia Harrington，小敘利亞計劃"
                },
            ],
            quote: [
                {
                    type: 'en',
                    default: "We didn't have a playground. We had the streets were our playground. And nobody hurt you and nobody– you weren't afraid of anything. You could stay out. You had to come in when it got dark, of course, but no– parents weren't afraid to allow their children to play outside. Syrian Town was just perfect, I think, as a child, for me."
                },
                {
                    type: 'ch',
                    default: "我們沒有遊樂場。我們的遊樂場就是街道。沒有人傷害你，你也不害怕任何事情。你可以在外面玩。當然天黑時你得回家，但父母們並不害怕讓孩子在外面玩。對兒時的我來說，小敘利亞真是完美。"
                },
                {
                    type: 'attribution',
                    default: "Betty Lutfy Dimeco"
                },
            ],
            resources: [
                {
                    title: "Boston Little Syria Project",
                    author: "",
                    date:"",
                    chTitle: "",
                    chAuthor: "",
                    chDate:"",
                    link: "www.bostonlittlesyria.org",
                },
                {
                    title: "Interview with Eva Peter Chojnowski",
                    author: "by Lydia Lowe",
                    date:"October 13, 2023",
                    chTitle: "",
                    chAuthor: "",
                    chDate:"",
                    link: "https://docs.google.com/document/d/1MW_1qeCNo12bUsy9MlqbSRuJ9b_KdLISmkIdNwEY6qM/edit?usp=sharing",
                },
                {
                    title: "Birthplace of 'The Prophet': Kahlil Gibran's life in Massachusetts",
                    author: "by Irina Matchavariani and Tiziana Dearing",
                    date:"July 5, 2023",
                    chTitle: "",
                    chDate: "",
                    link: "https://www.wbur.org/radioboston/2023/06/05/kahlil-gibran-prophet-massachusetts",
                },
                {
                    title: "The Kahlil Gibran Memorial",
                    author: "",
                    date:"",
                    chTitle: "",
                    chAuthor:"",
                    chDate: "",
                    link: "https://bostonlitdistrict.org/venue/khalil-gibran-memorial/",
                },
                {
                    title: "Wandering Boston's Little Syria",
                    author: "",
                    date:"",
                    chTitle: "",
                    chAuthor:"",
                    chDate: "",
                    link: "https://www.masshist.org/events/wandering-bostons-little-syria",
                },
            ]
        },
        {
            name: "Hudson Street 乞臣街",
            address: "116 Hudson Street",
            chineseAddress: "乞臣街116號",
            location: {"lat": 42.34823, "lng": -71.06119},
            cover: 
                "https://res.cloudinary.com/dmi3ywlal/image/upload/v1717843830/152_Davis_Woo_Husdon_-_Reggie_Wong_family_photo_hots0m.jpg",
            hint: "On summer nights, after sewing and cooking, mothers conversed on stoops, while fathers worked at restaurants. 夏夜，母親們在縫紉和做飯後坐在台階上聊天，而父親們則在餐館工作。",
            landmarkId :"g",
            id: "hudson-street",
            sign: true,
            images: [
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717843830/152_Davis_Woo_Husdon_-_Reggie_Wong_family_photo_hots0m.jpg',
                    caption:"",
                    chCaption:""
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717843826/HSF-002A_gon97t.jpg',
                    caption:"",
                    chCaption:""
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717843827/Hudson_st_wrecking_ball_v7diyi.jpg',
                    caption:"",
                    chCaption:""
                }, 
            ],
            description: [
                {
                    type: 'en', 
                    default: "Once the center of thriving Syrian/Lebanese and then Chinese working class populations, and the shops and institutions serving them, Hudson Street became the frontier facing powerful forces that threatened the Chinatown neighborhood."
                },
                {
                    type: 'en',
                    default: "Boston’s West End is widely recognized as the first neighborhood to be decimated by the federal urban renewal program, yet the process of reorganizing the city had already begun in Chinatown. From 1954 through the  1960s, the twin public forces of urban renewal and interstate highway construction hacked away at the neighborhood. Highway construction took one-third of the housing stock, and then three acres of land went to hospital and university construction."
                },
                {
                    type: 'en',
                    default: "On Hudson Street alone, road construction demolished sixty buildings and the even-numbered flank of the street. What remained after for decades was the odd-numbered Hudson Street row houses facing the blank concrete wall of Interstate 93. The Busy Corner Spa; St. John of Damascus Orthodox Church; Kin Company grocery; and the homes of Albert Woo, Salma Hadaya, Bak Moy, and Hee Wong were all gone."
                },
                {
                    type: 'en',
                    default: "Residents remembered, “They told everyone that they had to sell, they had to move, and that was it… They took all of Chinatown. They took Albany Street, Hudson Street. That was half the Chinese community originally. That’s what forced the Lebanese out, the Syrians out, and the Chinese out…The residents and businesses would be served with a notice, a piece of paper either tacked to the door or handed to the resident or business person, giving them sixty days to vacate the premises.”"
                },
                {
                    type: 'en',
                    default: "This was a bitter pill for the former residents, and for many years, they would organize periodic reunions and remembrances of the neighborhood life lost on the street. However, after many decades, the community was able to reclaim a sliver of the lost land that today hosts One Greenway, 66 and 88 Hudson, built by the Asian Community Development Corporation. The enduring memory of the old Hudson Street neighborhood’s strong community fabric has inspired new generations of activism and community-building."
                },
                {
                    type: 'ch',
                    default: "乞臣街曾經是朝氣蓬勃的敘利亞、黎巴嫩和中國工人階級的中心，也是為他們服務的商店和機構的中心，後來成為了對抗威脅華埠社區勢力的前沿。"
                },
                {
                    type: 'ch',
                    default: "波士頓西端被廣泛地認為是第一個被聯邦城市更新計劃摧毀的社區，但城市重組的進程已經在華埠開始。 從1954 年到1960 年代，市區重建和州際公路建設的雙重公共力量侵佔了這個社區。 高速公路建設拆毀了三分之一的房屋，另有三英畝土地被徵用於醫院和大學建設。"
                },
                {
                    type: 'ch',
                    default: "單是在乞臣街，因建設道路就拆除了六十棟建築物和街道的雙數一側。 幾十年後留下的是乞臣街面向 93 號州際公路的空白混凝土牆的單數排屋。 The Busy Corner Spa、聖約翰東正教教堂； Kin雜貨店； Albert Woo、Salma Hadaya、Bak Moy 和 Hee Wong 的家都消失了。"
                },
                {
                    type: 'ch',
                    default: "居民回憶道，「他們告訴所有人必須要賣掉，必須要搬家，就是這樣……他們佔領了整個華埠。 他們佔領了柯本尼街和乞臣街。 原來那是華人社區的一半。 這就是迫使黎巴嫩人離開、敘利亞人離開、中國人離開的原因……居民和商鋪都收到一份通知，一張紙條要麼釘在門上，要麼交給居民或商人，就給他們六十天的時間搬出其住址。」"
                },
                {
                    type: 'ch',
                    default: "這對以前的居民來說是一粒苦果，多年來他們都定期組織聚會，去紀念乞臣街上逝去的鄰里生活。 然而，幾十年後，該社區終於收復了一小片失去的土地，如今這裡有由亞美社區發展協會建造的綠茵苑（乞臣街66 號和 88 號）的建設項目。 舊乞臣街社區存在的堅實社區結構的持久記憶，激發了新一代的行動主義和社區建設動力。"
                },
            ],
            quote: [
                {
                    type: 'en',
                    default: 'Yet, that sweet memory of simple, youthful play remains. If you were to ask us, former children of Hudson Street, what it was like to live there, each of us will tell you about each house and who lived in it, every rail we slid down, every sidewalk we traversed, every pole we climbed, every stoop we sat on, and every face.  It was the landscape of our childhood: always sunny, always a playmate nearby.  The street was ours to share and in which to create imaginary worlds, a place where we played games of our own invention and making.  Implicit in the freedom we were given was the trust of our parents that we would always choose to do the right thing.'
                },
                {
                    type: 'ch',
                    default: "然而，那段簡單而青澀的玩耍的甜蜜記憶依然存在。如果你問我們這些前乞臣街的孩子們，在那裡生活是什麼樣子，每個人都會告訴你每棟房子和住在那裡的人，我們滑下的每一條欄杆，我們走過的每一條人行道，我們爬過的每一根柱子，我們坐過的每一個門廊，以及每一張面孔。這是我們童年的景觀：總是陽光明媚，總有一個小伙伴在附近。我們共享這條街，在這裡創造想象中的世界，在這裡玩我們自己發明和創造的遊戲。在我們被賦予的自由中，隱含著父母對我們的信任，他們相信我們總是會選擇做正確的事情。"
                },
                {
                    type: 'attribution',
                    default: 'Cynthia Yee, a Hudson Street resident displaced by the construction of the Central Artery in 1962. If Hudson Street Could Talk, Hudson Street Chronicles. Cynthia Yee，前乞臣街居民，因1962年93號州際高速公路的建設而被逼遷。摘自「如果乞臣街會說話」，乞臣街編年史。'
                }
            ],
            resources: [
                {
                    title: "Hudson St Chronicles",
                    author: "by Cynthia Yee",
                    date:" February 16, 2018",
                    chTitle: "",
                    chAuthor: "",
                    chDate:"",
                    link: "https://hudsonstreetchronicles.com/2018/02/16/if-hudson-street-could-talk/",
                },
                {
                    title: 'Washing',
                    author: "by Lily Xie",
                    date:"2020-2022",
                    chTitle: "",
                    chAuthor: "",
                    chDate:"",
                    link: "https://lily-xie.com/washing",
                },
            ],
        },
        {
            name: "International Ladies Garment Workers Union 國際女服工會",
            address: "33 Harrison Avenue",
            chineseAddress: "夏利臣道33號",
            location: {"lat": 42.3516759, "lng": -71.0611478},
            cover: 
                "https://res.cloudinary.com/dmi3ywlal/image/upload/v1717843208/image_t48neq.png",
            hint: "The group of women workers, we are very powerful. 我們這班婦女是很厲害的。",
            landmarkId :"h",
            id: "international-ladies-garment-workers-union",
            sign: true,
            images: [
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717843208/BostonMaidWorkers_ax1fzz.png',
                    caption:"ILGWU workers on strike, Francis Cabot Lowell Mill on Moody Street, Lowell, 1940s. (Photo courtesy of UMass Boston Open Archives)",
                    chCaption:" 國際女服工會工人在罷工，洛厄爾穆迪街的弗朗西斯·卡博特·洛厄爾工廠 (Francis Cabot Lowell Mill)，1940年代。（照片由麻州大學波士頓公開檔案提供）",
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717843345/talking-at_rally_lepbig.jpg',
                    caption:"Chinese women garment workers demanded retraining as factories shut down in the 1980s. (Photo courtesy of Therese Feng, Chinese Progressive Association)",
                    chCaption:"1980年代，隨著工廠關閉，華裔女工要求再培訓。（照片由華人前進會Therese Feng提供）"
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717843345/talking-at_rally_lepbig.jpg',
                    caption:"Garment worker leaders and community supporters in a negotiation meeting. (Photo courtesy of Therese Feng, Chinese Progressive Association)",
                    chCaption:"紡織工人領袖與社區支持者在談判會議中。（照片由華人前進會Therese Feng提供）"
                }, 
                {
                    link:'https://res.cloudinary.com/dmi3ywlal/image/upload/v1717843209/IMG_6498_xdh7yx.jpg',
                    caption:"Former garment workers recall life in the factory. (Photo courtesy of Heidi Shin)",
                    chCaption:"前紡織工人回憶工廠生活。（照片由Heidi Shin提供）"
                }, 
            ],
            description: [
                {
                    type: 'en', 
                    default: "The International Ladies’ Garment Workers Union (ILGWU) was once one of the largest labor unions in the United States and one of the first to have a majority female membership, with a long history of organizing immigrant workers of all backgrounds. The ILGWU was founded in 1900 via the merger of several garment workers unions in and around New York City, heavily influenced by Yiddish socialism."
                },
                {
                    type: 'en',
                    default: "Boston, a leading city in the women’s garment industry—with most manufacturers clustered on and around Kneeland Street—was an important center for garment worker organizing. The Denison House, a women-run settlement house on Tyler Street, hosted some of these early trade union meetings."
                },
                {
                    type: 'en',
                    default: "In 1907, initially against the wishes of the union leadership, two thousand Boston garment workers went on strike to win full recognition of their union from employers. Although the workers ultimately lost the strike, the labor action helped to energize ILGWU organizing and militancy in Boston and far beyond.  A two-month labor stoppage in Boston and Cambridge launched in February 1936 by  4,000 garment workers won improved wages and a 40-hour (instead of 52-hour) work week."
                },
                {
                    type: 'en',
                    default: "In January 1938, the International Ladies Garment Workers Union (ILGWU) purchased the eight-story building at 33 Harrison Avenue to house its New England offices. The union renovated the premises to accommodate an ILGWU radio station as well as a health center, one staffed by 24 physicians and specialists, to provide medical care to 15,000 union members from Boston and its vicinity."
                },
                {
                    type: 'en',
                    default: "The membership of the ILGWU in Boston reflected the garment industry’s workforce: mainly immigrants, initially people of Polish and German (mostly Jewish) descent, with many of Italian descent as well. The ILGWU grew to about 450,000 members across the United States at its height in the 1950s. With the repeal of exclusion laws following World War II and liberalized immigration quotas in the 1960s, Chinese immigrant women transformed the local garment industry workforce, becoming the majority of the ILGWU membership here by the 1980s."
                },
                {
                    type: 'en',
                    default: "Garment factories provided an entry into the workforce for Chinese immigrant women. The work required little English fluency, sometimes with flexible hours or the ability to bring work home. ILGWU members had the added benefit of health coverage for families in which the men often worked in restaurants with no benefits. With the closure of many garment factories from the mid-1980s on, rank-and-file Chinese garment workers led a public campaign to demand job retraining that established New England’s first bilingual workforce training programs."
                }, 
                {
                    type: 'en',
                    default: "In July 1995, the ILGWU joined with the Amalgamated Clothing and Textile Workers Union to form UNITE (Union of Needletrades, Industrial and Textile Employees) And in 2004, UNITE merged with the Hotel Employees Restaurant Employees Union (HERE) to create UNITE HERE! Today the union has about 300,000 members in the United States and Canada. The building at 33 Harrison Avenue serves as the headquarters of the union’s New England Joint Board."
                },
                {
                    type: 'ch',
                    default: "國際女服工會 (ILGWU) 曾經是美國最大的工會之一，也是最早以女性成員為主的工會之一，在組織各種背景的移民工人方面有著悠久的歷史。 國際女服工會成立於 1900 年，由紐約市及週邊地區的幾個服裝工人工會合併而成，深受意第緒社會主義的影響。"
                },
                {
                    type: 'ch',
                    default: "波士頓是女服裝業的領先城市，大多數製造商都聚集在尼倫街及其週邊地區，是服裝工人組織的重要中心。 泰勒街的丹尼森之家 (Denison House) 是一所由婦女經營的安置所，曾舉辦了一些早期的工會會議。"
                },
                {
                    type: 'ch',
                    default: "1907 年，最初違背工會領導層的意願，兩千名波士頓製衣工人舉行罷工，以贏得雇主對工會的充分認可。 儘管工人們最終罷工失敗，但勞工抗議幫助激發了波士頓及其他地區的國際女服工會組織和抗爭精神。1936 年 2 月，波士頓和劍橋的 4,000 名服裝工人發起了為期兩個月的停工，贏得了更高的工資和每週 40 小時（而不是 52 小時）的工作時間。"
                },
                {
                    type: 'ch',
                    default: "1938 年 1 月，國際女服工會 (ILGWU) 購買了夏利臣道 33 號的八層樓建築，作為其在紐英倫辦事處的所在地。工會翻修了場地，以容納國際女服工會廣播電台和健康中心，該中心擁有 24 名醫生和專家，為來自波士頓及週邊地區的 15,000 名工會成員提供醫療服務。"
                },
                {
                    type: 'ch',
                    default: "波士頓國際女服工會的成員反映了服裝業的勞動力：主要是移民，最初是波蘭人和德國人（主要是猶太人）後裔，其中也有許多義大利人後裔。國際女服工會在 1950 年代達到鼎盛時期，在全美擁有約 45 萬名會員。 隨著二戰後排斥法的廢除和 20 世紀 60 年代移民配額的放寬，中國移民婦女改變了當地服裝業的勞動力市場，到1980 年代成為國際女服工會成員的大多數。"
                },
                {
                    type: 'ch',
                    default: "製衣廠為中國移民婦女提供了進入勞動市場的機會。 這項工作幾乎不需要英語流利，有時可以提供彈性的工作時間或能夠在家辦公。 國際女服工會會員也享受到家庭健康保險的額外好處，因為家裡的男性經常在餐廳工作，沒有任何福利。 自 1980 年代中期以來，隨著許多製衣廠的關閉，製衣廠的婦女華工發起了一場公開運動，要求進行職業再培訓，從而建立了紐英倫第一個雙語勞動力培訓計劃。"
                },
                {
                    type: 'ch',
                    default: "1995 年 7 月，國際女服工會與聯合服裝和紡織工人工會聯合成立了 UNITE（縫紉、工業和紡織僱員工會）。 如今，該工會在美國和加拿大擁有約 30 萬名會員。 夏利臣道 33 號的大樓是工會紐英倫聯合委員會的總部。"
                },
            ],
            quote: [
                {
                    type: 'en',
                    default: "Because back then, if you were working in America, if you have a union, you have insurance. Once you're laid off, you won't have insurance. Back then, there was no insurance; that's why, when we were unemployed we didn't have insurance. If you went to look for training class, back then a training class was $5000. I knew it was $5000. $5000 for you to study for a couple of months, and you don't have health insurance, and you need to live, that's why we demanded job training; we demanded family security."
                },
                {
                    type: 'en',
                    default: "Because the group of women workers, we are very powerful. Because everyone has that kind of compassion, it’s the most important. The men, it's not like they’re not. But to be honest, here in Boston, all of the men work at restaurants. Working at a restaurant the hours are long so for them, it limits them. So, for the women, they are more active and know people's needs and are more meticulous. That's why we women are very aware. Everyone is very detailed."
                },
                {
                    type: 'ch',
                    default: "因為從前，如果你在美國做工，如果你有工會的話，你就會有醫療保險。但如果一旦你被解僱，你就會失去保險。以前是沒有保險的，因此，當我們失業時就沒有保險了。如果你想去找訓練課，那時的課程要$5000。我知道那時是需要$5000，$5000 讀兩三個月，又沒有健康保險，但又要生活，因此我們要求就業培訓，我們要求有家庭保障。"
                },
                {
                    type: 'ch',
                    default: "我們這班婦女是很厲害的，因為每個人都有一份熱心，那份熱心是最重要的，男人不是這樣，老實說，在波士頓有很多男人都是做餐館的，做餐館的時間很長，所以限制了他們，我們婦女們比較有時間和了解人們的需要，比較細心一些，所以我們一班婦女真是很了不起的，每個人做事都很細心。"
                },
                {
                    type: 'attribution',
                    default: 'Mee Len Hong, former garment worker leader 湯美蓮，前紡織工人領袖'
                }
            ],
            resources: [
                {
                    title: "The Labor Union in the Ladies’ Garment Industry, M.A.",
                    author: "by Saul Danburg, Boston University Graduate School",
                    date:"1936",
                    chTitle: "",
                    chAuthor: "",
                    chDate:"",
                    link: "https://archive.org/details/laborunioninladi00danb/page/n1/mode/2up?",
                },
                {
                    title: "Chinese Immigrant Workers and Community-based Labor Organizing in Boston: Paving the Way",
                    author: "by Lydia Lowe, Amerasia Journal, 18(1), 1992: 39-48.",
                    date:"1992",
                    chTitle: "",
                    chDate: "",
                    link: "https://www.tandfonline.com/doi/pdf/10.17953/amer.18.1.r3k2g42226r755l2",
                },
                {
                    title: "International Ladies’ Garment Workers Unions",
                    author: "Emily Stochl interview with Nick Juravich, Pre-loved Podcast",
                    date:"June 1, 2020",
                    chTitle: "",
                    chDate: "",
                    link: "https://prelovedpod.libsyn.com/s4-ep17-international-ladies-garment-workers-union-with-dr-nick-juravich-on-labor-and-union-history-and-the-role-of-the-ilgwu-in-garment-worker-organizing",
                },
                {
                    title: "The Ladies’ Garment Industry in Boston: A Study of Characteristics Affecting Choice of Location",
                    author: "by Albert Anthony Tappé, Department of City and Regional Planning, Massachusetts Institute of Technology",
                    date:"March 10, 1958",
                    chTitle: "",
                    chDate: "",
                    link: "https://dspace.mit.edu/handle/1721.1/76897",
                },
                {
                    title: "Through Strength and Struggle: Boston's Asian American Student/Community/Labor Solidarity",
                    author: "by Peter N. Kiang and Man Chak Ng, Amerasia Journal 15(1), 1989: 285-293",
                    date:"January 1989",
                    chTitle: "",
                    chDate: "",
                    link: "https://www.researchgate.net/publication/285040076_Through_Strength_and_Struggle_Boston's_Asian_American_StudentCommunityLabor_Solidarity",
                },
                {
                    title: "Interview with Mee Len Hong",
                    author: "by Ann Har-Yee Wong and Lydia Lowe",
                    date:"September 6, 2020",
                    chTitle: "",
                    chDate: "",
                    link: "https://docs.google.com/document/d/1ASjp82wGzLj6_bzRrt2bgsqCHbiXh8p2rAD6vf94N_0/edit?usp=sharing",
                },
            ]
        },
        {
            name: "John Assaf’s Home 約翰·阿薩夫之家",
            address: "2 Johnny Court",
            chineseAddress: "約翰尼庭院2號",
            location: {"lat": 42.3482114, "lng": -71.0630521},
            cover: 
                "https://drive.google.com/file/d/1SR_dr39G9Bw7jGOWmVIxOnB3yHr3_FVQ/view?usp=drive_link",
            hint: "A messenger in the community 社區裡的信使",
            landmarkId :"i",
            id: "john-assafs-home",
            sign: true,
            images: [
                {
                    link:'https://drive.google.com/file/d/1GXderh0NRHzJE8JKc5mZMZ9Tp-LHDM09/view?usp=drive_link',
                    caption:"The Assaf family: Left to right front: Fahime, Wadia. Second row: Wardé, Georges, Saada holding infant John. Chafika in rear. (Photo courtesy of the Solomon Family, c. 1921)",
                    chCaption:"阿薩夫家族：前排從左至右：法西米、瓦迪亞。第二排：瓦爾德、喬治、薩達抱著嬰兒約翰。夏菲卡在後排。（照片由所羅門家族提供，約攝於1921年）",
                }, 
                {
                    link:'https://drive.google.com/file/d/11od-HN8qwB1a1IeOQ9g60OmolkbOOq9-/view?usp=drive_link',
                    caption:"Boston Housing Authority. 'Johnny Court, northwesterly from Harrison Avenue.' Photograph. December 4, 1956. Digital Commonwealth",
                    chCaption:"波士頓住房局。「約翰尼庭院，從哈里遜大道往西北方向拍攝。」照片。1956年12月4日。數位共享資源"
                }, 
                {
                    link:'https://drive.google.com/file/d/1SR_dr39G9Bw7jGOWmVIxOnB3yHr3_FVQ/view?usp=drive_link',
                    caption:"Johnny Court circa 1976 - 'Street view of Johnny Court in Boston's Chinatown.' Photograph. Digital Commonwealth",
                    chCaption:"約1976年的約翰尼庭院 - 「波士頓華埠約翰尼庭院的街景。」照片。數位共享資源"
                }, 
                {
                    link:'https://drive.google.com/file/d/1-GBlu4d9iB0XwX_G844ioo-J8KmkWKVy/view?usp=sharing',
                    caption:"Rosanne Solomon held by her uncle on Johnny Court (Photo courtesy of Rosanne Solomon)",
                    chCaption:"羅珊·所羅門在約翰尼庭院被她叔叔抱著（照片由羅珊·所羅門提供）"
                }, 
                {
                    link:'https://drive.google.com/file/d/1jd2pHr1d4kLl3tlXNcuPcP0mwMsoYCR5/view?usp=drive_link',
                    caption:"News clipping from Sunday Advertiser, c. 1956",
                    chCaption:"星期日廣告報剪報，約1956年"
                }, 
                {
                    link:'https://drive.google.com/file/d/14l01nReNWkw5bUoB8tuO_7_PbSxtj0jW/view?usp=drive_link',
                    caption:"Women standing at the corner of Johnny Court and Harrison Avenue (Photo courtesy of Rosanne Solomon)",
                    chCaption:"婦女們站在約翰尼庭院和哈里遜大道的轉角處（照片由羅珊·所羅門提供）"
                }, 
            ],
            description: [
                {
                    type: 'en', 
                    default: "Johnny Court, formerly called Maple Place, is one of the original small scale streets of Early Greek Revival brick row houses that made up the South Cove neighborhood now known as Chinatown. The street, originally named Maple Place, and built as early as 1837, was first occupied by Irish, Italian and Jewish residents, then Syrian immigrants beginning in the early 1900s, followed by the Chinese."
                },
                {
                    type: 'en',
                    default: "Syrian immigrants George and Sadie Assaf moved into 2 Maple Place in 1922, In the same year, they welcomed their child John, one of 11 children, who was affectionately nicknamed Johnny. Shortly after Johnny’s birth, he became paralyzed and mostly confined to Maple Place and his neighborhood."
                },
                {
                    type: 'en',
                    default: "After Johnny moved out in 1956, the people of Maple Place advocated for the street name to be changed to “Johnny Court” in his honor because of the impact he had on the community. John Assaf was not only the face of this street and neighborhood, but his overcoming of his physical challenges also symbolized the resilience that characterized life in the row houses."
                },
                {
                    type: 'en',
                    default: "Over the decades, the Johnny Court row houses would continue to be home for new generations of immigrant, working class families.  Lynn Wah Wong, who lived in this building in the 1970s, said, “Johnny Court was a quaint and quiet little street, the perfect place to raise a little family.”"
                },
                {
                    type: 'en',
                    default: "In the 1980s and 1990s, crime rose in the neighborhood after a Boston decision to site the only adult entertainment zone, known as the Combat Zone, next to Chinatown. During that period, the quiet street of Johnny Court became a common spot for prostitutes to bring their customers.  When the Combat Zone waned and luxury developments moved in, many row houses were emptied and became short-term rental properties until the passage of a Short Term Rental Ordinance in 2018."
                },
                {
                    type: 'en',
                    default: "Meiqun Huang, who lived here until 2015, said, “Many immigrant working class families living in row houses have been forced to move out or face the threat of displacement during ownership turnover.”"
                }, 
                
                {
                    type: 'ch',
                    default: "約翰尼庭院，原名楓葉巷，是如今被稱為唐人街的南灣區最早期的希臘復興風格排屋小巷之一。這條街始建於1837年，最初居住著愛爾蘭、意大利和猶太居民，到了1900年代初期開始有敘利亞移民遷入，其後便是華人群體。"
                },
                {
                    type: 'ch',
                    default: "1922年，敘利亞移民喬治和薩迪·阿薩夫搬入楓葉巷2號。同年，他們迎來了十一個孩子中的一個，取名約翰，暱稱約翰尼。不幸的是，約翰尼出生後不久就癱瘓了，生活基本上局限在楓葉巷和周邊街區。"
                },
                {
                    type: 'ch',
                    default: "1956年強尼搬離後，由於他對社區的深遠影響，楓葉巷的居民積極倡議將街名改為“約翰尼庭院”以示紀念。約翰·阿薩夫不僅是這條街巷的象徵人物，他克服身體障礙的精神也成為了排屋生活堅強不屈生命力的縮影。"
                },
                {
                    type: 'ch',
                    default: "幾十年來，約翰尼庭院的排屋持續為新一代移民工薪家庭提供棲身之所。1970年代曾住在這裡的黃林華說：“約翰尼庭院是條古樸寧靜的小巷，是撫養小家庭的理想之地。”"
                },
                {
                    type: 'ch',
                    default: "到了1980和1990年代，波士頓決定在唐人街旁設立該市唯一的成人娛樂區（即“戰區”）後，該區域犯罪率上升。在那段期間，曾經寧靜的約翰尼庭院成為了性工作者接待顧客的常見場所。隨著戰區沒落和豪華住宅區的興起，許多排屋被騰空改作短期出租物業，直到2018年短期租賃條例出台。"
                },
                {
                    type: 'ch',
                    default: "曾在此居住至2015年的黃美群表示：“在物業易主過程中，許多住在排屋裡的移民工薪家庭被迫搬離，或面臨著被迫遷的威脅。”"
                },
            ],
            quote: [
                {
                    type: 'en',
                    default: "He used to have a stool, and he would take it to the corner…where the lamppost was, and he’d put his stool and he’d sit there because he’d get tired walking. He couldn’t walk too far…So people passing would say, ‘Johnny, when you see my husband come by, tell him I won't be home until five o'clock. If you see my kids come by, tell them to go to Auntie Sophie's house. We're not home.’  So he was like a messenger in the community. He sat there all the time, and everybody had something to tell him to tell somebody."
                },
                {
                    type: 'ch',
                    default: "他總是帶著一張小凳子，會把它擺在街角燈柱旁...他就坐在那裡，因為走路容易疲倦，走不了太遠...路過的街坊鄰居常會對他說：‘約翰尼啊，等會兒要是看到我丈夫經過，跟他說我五點才回家。要是看到我的孩子們，就告訴他們去蘇菲阿姨家，我們不在家。’他就這樣成為了社區裡的信使。他日復一日坐在那裡，每個人都會託付他傳話給別人。"
                },
                {
                    type: 'attribution',
                    default: 'Rosanne Solomon, John Assaf’s niece 羅珊娜·所羅門，約翰·阿薩夫的外甥女'
                }
            ],
            resources: [
                {
                    title: "Chinatown Celebrates Row-House Win: Row Houses Preserved for Working and Immigrant Residents.",
                    author: "by Alexandra Chaidez, Boston Globe",
                    date:"August 16, 2021",
                    chTitle: "",
                    chAuthor: "",
                    chDate:"",
                    link: "https://www.bostonglobe.com/2021/08/15/metro/chinatown-residents-celebrate-new-affordable-housing-block-party/",
                },
                {
                    title: "Chinatown-South Cove Comprehensive Survey Project Final Survey Report",
                    author: "by Arthur Krim, City of Boston Landmarks Commission",
                    date:"July 11, 1997",
                    chTitle: "",
                    chDate: "",
                    link: "https://mhc-macris.net/Documents/NR/64001457.pdf",
                },
                {
                    title: "Forever Struggle: Activism, Identity, and Survival in Boston’s Chinatown, 1880-2018.",
                    author: "by Michael Liu, University of Massachusetts Press",
                    date:"2020",
                    chTitle: "",
                    chDate: "",
                    link: "https://www.umasspress.com/9781625345462/forever-struggle/",
                },
                {
                    title: "The Choice of Love: The immigration of the Assafs to the United States of America.",
                    author: "by Peter J Solomon",
                    date:"April 20, 1997",
                    chTitle: "",
                    chDate: "",
                    link: "https://www.maronite-institute.org/MARI/JMS/april97/The_Choice_of_Love.htm",
                },
                {
                    title: "Row-house row gets city sympathy - Walsh calls preservation efforts 'complicated'",
                    author: "by Brooks Sutherland, Boston Herald",
                    date:"May 1, 2019",
                    chTitle: "",
                    chDate: "",
                    link: "https://infoweb.newsbank.com/apps/news/document-view?p=WORLDNEWS&docref=news/173256CA8CF3CFA8",
                },
                {
                    title: "Residents, Advocates, Work to Save Chinatown Row Houses",
                    author: "by Milton J. Valencia, Boston Globe",
                    date:"April 30, 2019",
                    chTitle: "",
                    chDate: "",
                    link: "https://www.bostonglobe.com/metro/2019/04/30/residents-advocates-work-save-chinatown-row-houses/cbAFoMh4nfb2KpTW550lJJ/story.html",
                },
            ]
        },
    ]

export default data;